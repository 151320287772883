<template>
  <v-row dense>
    <v-col cols="12">
      <v-card
        class="mx-auto"
        subtitle="Please contact driving school for queires"
        title="Manage Booking"
        width="100%"
        ><v-card-text>
          <p>
            <span>Lessons type:</span>
            {{ leanerdata.learnerInfo.lessonsType }}
          </p>
          <p>
            <span>Instructor:</span>
            {{ leanerdata.instructorInfo.name }}
          </p>
          <p>
            <span>Lesson on:</span><span class="cp"> 02-05-2024 at 10:15</span>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="mx-auto" title="Reschedule Booking" width="100%">
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            color="amber"
            @click="showReschule = !showReschule"
            variant="flat"
            >Select New Date & Time</v-btn
          >
        </v-card-actions>
        <v-expand-transition>
          <div v-show="showReschule">
            <v-divider></v-divider>
            <v-card-item>
              <BookLessonCard :credit="bookCredit" />
            </v-card-item>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="mx-auto" title="Cancel Booking" width="100%">
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="red" variant="flat">Click to canel this booking</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import BookLessonCard from "@/components/BookLessonCard.vue";
import { useLearnerStore } from "@/store/learner-store";
import { ref, computed } from "vue";

const leanerdata = useLearnerStore();
const showReschule = ref(false);

const lessonCredits = [
  { id: 1000, paymentid: 1445 },
  { id: 1002, paymentid: 1455 },
  { id: 1003, paymentid: 1455 },
  { id: 1004, paymentid: 1465 },
  { id: 1005, paymentid: 1465 },
];
console.log(lessonCredits);
const bookCredit = computed(function () {
  if (lessonCredits.length > 0) {
    return lessonCredits[0];
  }
  return null;
});
</script>

<style lang="scss" scoped>
p {
  line-height: 1.5rem;
  span {
    font-weight: 600;
  }
  span.cp {
    color: rgb(240, 17, 73);
  }
}
</style>
