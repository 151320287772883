<template>
  <v-row dense>
    <v-col cols="12" v-for="panel in panels" :key="panel.id">
      <v-card
        class="mx-auto"
        variant="outlined"
        append-icon="mdi-chevron-right"
        :prepend-icon="panel.ico"
        :subtitle="panel.sub"
        :title="panel.text"
        :to="panel.link"
        width="100%"
        :disabled="panel.isReadonly"
      >
        <template v-slot:prepend>
          <v-icon :icon="panel.ico" color="primary" size="x-large"></v-icon>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
const panels = [
  {
    id: 1,
    text: "Book a lesson",
    sub: "Book lessons with you credit",
    link: "booklesson",
    ico: "mdi-calendar-outline",
    isReadonly: false,
  },
  {
    id: 2,
    text: "Upcomming Lessons",
    sub: "Manage upcoming lessons here",
    link: "upcomingbookings",
    ico: "mdi-calendar-multiselect-outline",
    isReadonly: false,
  },
  {
    id: 3,
    text: "Completed Lessons",
    sub: "See all completed lessons",
    link: "completedbookings",
    ico: "mdi-calendar-check-outline",
    isReadonly: false,
  },
  {
    id: 4,
    text: "Lessons Payment History",
    sub: "See all lesson payments",
    link: "paymenthistory",
    ico: "mdi-calendar-clock-outline",
    isReadonly: false,
  },
];
</script>

<style lang="scss" scoped></style>
