<template>

            <v-row dense>             
              <v-col cols="12">
                <v-card
                  class="mx-auto"                        
                  subtitle="Please contact driving school for queires"
                  title="Upcoming lessons"                  
                  width="100%"
                  
                ><v-card-text>Selelect booking to reschedule or cancel.
                    Note: You can not reschedule lessons booked for next 48 hours also cancelations of lessons booked for next 48 hours are non-refundable.</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-for="booking in ratingPendingBookings" :key="booking.id">
                <v-card
                  class="mx-auto"
                  variant="outlined"
                  :subtitle="booking.sub"
                  :title="booking.text"                  
                  width="100%"
                  :disabled="booking.isReadonly"
                  to="managebooking"
                >
                  <template v-slot:prepend>
                    <v-icon
                      icon="mdi-account-clock-outline"
                      color="primary"
                      size="x-large"
                    ></v-icon>
                  </template>
                  <template v-slot:append v-if="booking.isEditable">
                    <v-icon
                      icon="mdi-chevron-right"
                      color="primary"
                      size="x-large"
                      
                    ></v-icon>
                  </template>
                </v-card>
              </v-col>              
            </v-row>
          
  </template>
  
  <script setup>
 

  const ratingPendingBookings =  [
    {
      id: 1,
      text: "Starting at 30-04-2024 16:30",
      sub: "Booking:  B1201",
      bookingID: 1001,
    //   ico: "mdi-check",
      isReadonly: false,
      isEditable: false,
    },
    {
      id: 2,
      text: "Starting at 02-05-2024 15:30",
      sub: "Booking:  B1204",
      bookingID: 1001,
    //   ico: "mdi-check",
      isReadonly: false,
      isEditable: false,
    },
    {
      id: 3,
      text: "Starting at 05-05-2024 16:15",
      sub: "Booking:  B1209",
      bookingID: 1001,
    //   ico: "mdi-numeric-1",
      isReadonly: false,
      isEditable: true,
    },
    {
      id: 4,
      text: "Starting at 15-05-2024 12:30",
      sub: "Booking:  B1214",
      bookingID: 1001,
    //   ico: "mdi-check",
      isReadonly: false,
      isEditable: true,
    },
   
   
  ];
  </script>
  
  <style lang="scss" scoped></style>
  