<template>
    <div class="text-center">  
      <v-overlay
        :model-value="spinner.isBlockSpinnerLoading"
        class="align-center justify-center cursor-wait"
        :close-on-back="false"
        :close-on-content-click="false"
        :persistent="true"
      >
        <v-progress-circular
          color="warning"
          size="100"
          width="6"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
  </template>

<script setup>
import { useCommonStore } from '@/store/common-store'
const spinner = useCommonStore()

</script>