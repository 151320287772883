<template>
  <v-row dense>
    <v-col cols="12">
      <v-card
        class="mx-auto"
        :subtitle="`Learner ID: L${storeLeanerData.learnerInfo.learnerID}`"
      >
        <template v-slot:title>
          <span class="font-weight-black">{{
            `${storeLeanerData.learnerInfo.title} ${storeLeanerData.learnerInfo.name}`
          }}</span>
        </template>
        <template v-slot:prepend>
          <v-avatar color="blue-darken-2" size="64">
            <v-icon icon="mdi-school" size="50"></v-icon>
          </v-avatar>
        </template>

        <v-card-text class="bg-surface-light pt-4">
          <p>
            <span>Lessons Type:</span>
            {{ storeLeanerData.learnerInfo.lessonsType }}
          </p>
          <p>
            <span>Completed Lessons: </span
            >{{ storeLeanerData.learnerBookingInfo.completed }}
          </p>
          <p>
            <span>Upcoming Lessons: </span
            >{{ storeLeanerData.learnerBookingInfo.upcoming }}
          </p>
          <p>
            <span>Available Lessons: </span
            >{{ storeLeanerData.learnerCreditInfo.available }}
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <BuyLessonsCard />
    </v-col>
  </v-row>
</template>

<script setup>
import BuyLessonsCard from "@/components/BuyLessonsCard.vue";
import { useLearnerStore } from "@/store/learner-store";
const storeLeanerData = useLearnerStore();
</script>

<style lang="scss" scoped>
p {
  line-height: 1.5rem;
  span {
    font-weight: 600;
  }
}
</style>
