<template>
  <v-app>
    <LoadingBlockSpinner />
    <v-system-bar color="primary">
      <v-icon icon="mdi-wifi-strength-4"></v-icon>
      <v-icon icon="mdi-signal" class="ms-2"></v-icon>
      <v-icon icon="mdi-battery" class="ms-2"></v-icon>

      <span class="ms-2">{{ topTime }}</span>
    </v-system-bar>
    <AppToolBar class="mt-5" />
    <v-main>
      <v-container>
        <router-view v-slot="{ Component }">
          <transition
            enter-active-class="animate__animated animate__faster animate__fadeInUp"
            leave-active-class="animate__animated animate__faster animate__fadeOutDown"
            mode="out-in"
          >
            <keep-alive> <component :is="Component" /> </keep-alive
          ></transition>
        </router-view>
        <!-- This is to show if offline -->
        <v-snackbar v-model="net" color="deep-orange-accent-4" elevation="24">
          Connection <strong>offline</strong>.
        </v-snackbar>
      </v-container>
    </v-main>
    <BottomNav />
  </v-app>
</template>
<script setup>
import AppToolBar from "@/components/AppToolBar.vue";
import BottomNav from "@/components/BottomNav.vue";
import LoadingBlockSpinner from "@/components/Loaders/LoadingBlockSpinner.vue";

import { useCommonStore } from "@/store/common-store";
import { computed } from "vue";
const common = useCommonStore();
const net = computed(() => {
  return !common.isConnectionLive;
});
const topTime = computed(() => {
  var t = new Date()    
  return t.toLocaleString('en-UK', { hour: 'numeric', minute: 'numeric', hour12: true})
})
</script>
