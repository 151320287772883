<template>
  <div>
    <v-toolbar color="primary" :dark="true">
      <transition
  
        mode="out-in"
        enter-active-class="animate__animated animate__faster animate__fadeInRight"
        leave-active-class="animate__animated animate__faster animate__fadeOutLeft"
      >
        <v-app-bar-nav-icon
          v-if="route.meta.backto"
          :to="route.meta.backto"
          icon="mdi-arrow-left"
        ></v-app-bar-nav-icon>
      </transition>
      <transition
      
        mode="out-in"
        enter-active-class="animate__animated animate__faster animate__fadeInRight"
        leave-active-class="animate__animated animate__faster animate__fadeOutRight"
      >
      <v-toolbar-title v-if="!route.meta.backto">BECO Drive</v-toolbar-title>
      </transition>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleTheme">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn icon @click="commonStore.bgLoadToggle">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>Reload data</v-list-item>
          <v-list-item>Manage Account</v-list-item>
          <v-list-item @click="toggleTheme">
            <v-icon>mdi-theme-light-dark</v-icon>App Settings</v-list-item
          >

          <v-list-item to="/">Log Out</v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-progress-linear
      buffer-value="50"
      color="lime"
      :indeterminate="commonStore.isBGLoading"
    ></v-progress-linear>
  </div>
</template>

<script setup>
import { useCommonStore } from "@/store/common-store";
import { defineProps, ref } from "vue";
import { useRoute } from "vue-router";
const commonStore = useCommonStore();

import { useTheme } from "vuetify";

const theme = useTheme();
function toggleTheme() {
  theme.global.name = theme.global.current.dark ? "light" : "dark";
}
//props
defineProps({
  isBack: Boolean,
  link: String,
});
const backLink = ref("");
const route = useRoute();
console.log("We are going back to: " + route.meta.backto);
if (route.meta.level > 1) {
  //definitly a child route
  //find the parent and add it to back button
  //the current path is on matched -> just get the one before last one to go back
  let allRoutes = route.matched;
  console.log("PRINGNG THE MACHED -----");
  backLink.value = allRoutes.at(-2).path;
  console.log(allRoutes.at(-2).path);
}
console.log(route);
</script>

<style></style>
