<template>
  <router-view v-slot="{ Component, route }">
    <transition
      :enter-active-class="route.meta.enterClass"
      :leave-active-class="route.meta.leaveClass"
      mode="in-out"
    >
      <component :is="Component" /> </transition
  ></router-view>
</template>
<script setup>
import { useCommonStore } from "@/store/common-store";
console.log("This should only create onece")
const common = useCommonStore();
//change pinia status if offline
window.addEventListener("offline", () => {
  common.setOffline();
});
//change pinia status if online
window.addEventListener("online", () => {
  common.setOnline();
});
</script>

<style lang="scss"></style>
