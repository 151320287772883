<template>
  <div>
    <v-app>
    <v-container id="home" class="mb-6">
      <v-row>
        <!-- <v-col class="mx-auto" cols="1"> <p>This is a container</p></v-col> -->
        <v-col  align-self="center" class="mx-auto my-auto bg-surface-variant" cols="10" sm="12" lg="4" md="4" >
         
            <v-card elevation="2" class="pa-3" shaped>
              <a href="#" name="logo" title="logo">
                <!-- <v-img src="../assets/mlogo.png" contain height="200"></v-img> -->
                <!-- <v-img src="../assets/logotwo.jpg" contain height="200"></v-img> -->
                <!-- <v-img
                  src="https://via.placeholder.com/200.png?text=LOGO"
                  contain
                  height="200"
                  src="../assets/img/__becodrivelogo.svg"
                ></v-img> -->
                <v-img
                  class="mx-10"
                  src="https://via.placeholder.com/200.png?text=LOGO"
                  contain
                  height="200"
                ></v-img>
              </a>
              <div class="text-center">
                <p style="font-size: 1.6rem">Leaner Login</p>
               
                
                
                
              </div>
              <v-alert
              v-model="alert"
                  class="ma-1"
                  density="compact"
                  :text="alertTxt"
                  title="Login Error"
                  type="error"
                  border="start"
                  variant="tonal"
                  closable
                ></v-alert>
              <v-card-text>
                <v-text-field
                  v-model="logID"
                  label="User ID"
                  name="userid"
                  prepend-inner-icon="mdi-account-circle-outline "
                  type="number"
                  placeholder="Enter Your User ID"
                  class="rounded-1"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="logPass"
                  label="Password"
                  name="pass"
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="showIco"
                  @click:append="toggleShow"
                  :type="txtBoxType"
                  placeholder="Enter Your Password"
                  class="rounded-1"
                  outlined
                  clearable
                  v-on:keyup.enter="
                    loader = 'loading';
                    genSHA();
                  "
                >
                  <!-- suffix="| Forgot?" -->
                </v-text-field>
                <div class="text-center">
                  <v-btn
                    text
                    color="info"
                    class="mb-5"
                    @click.stop="modal = true"
                    >Forgot Password?</v-btn
                  >
                </div>
                <v-dialog v-model="modal" max-width="450">
                  <v-card>
                    <v-card-title class="text-h4">
                      Forgot Password
                    </v-card-title>

                    <v-card-text class="body-1">
                      If you are an office user please contact office manager to
                      reset the password.<br />
                      <br />If you are an office manager/office administrator
                      please contact software support.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="green darken-1" text @click="modal = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn to="/appview/dash" class="rounded-1"
                  color="primary"
                  x-large
                  block >App Home >></v-btn>

                <!--v-btn
                  class="rounded-1"
                  color="primary"
                  x-large
                  block
                  :loading="loading"
                  :disabled="loading"
                  @click="
                    loader = 'loading';
                    genSHA();
                  "
                  >Login
                </v-btn-->
              </v-card-text>
            </v-card>
        
          </v-col>
        <!-- <v-col class="mx-auto" cols="1" md-6> <p>This is a container</p></v-col> -->

      </v-row>
     
    </v-container> 
    
  </v-app>
  </div> 
  
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'LoginView',
  components: {
    //HelloWorld
  },
  data() {
    return{
      loading: false,
      modal: false,
      txtBoxType: '',
      toggleShow: '',
      showIco: '',
      logPass: '',
      logID: '',
      alert: true,
      alertTxt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus!'
    }
  }
}
</script>
<style scoped>
#home{
  height: 100vh;
}
</style>
