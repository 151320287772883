import { defineStore } from 'pinia'
//doing in option style for simplycity

export const useCommonStore = defineStore('common', {
    state: () => ({
        isOnLine: navigator.onLine,
        isBGSpinner: false,
        isBlockSpinner: false,// this is a full screen spinner to block all touches         
        }),
    
    actions: {
        bgLoading(){
            this.isBGSpinner = true
        },
        bgLoaded(){
            this.isBGSpinner = false
        }, 
        bgLoadToggle() {
            this.isBGSpinner = !this.isBGSpinner 
        },
        setOnline(){
            this.isOnLine = true;

        },     
        setOffline(){
            this.isOnLine = false;
        }     
    },
    getters: {
        isBGLoading: (state) => state.isBGSpinner,
        isBlockSpinnerLoading: (state) => state.isBlockSpinner,
        isConnectionLive: (state) => state.isOnLine,
    },
    persist: true
  })