<template>
   
            <v-row dense>             
              <v-col cols="12">
                <v-card
                  class="mx-auto"                        
                  subtitle="Rate three simple questions"
                  title="Select a previous lesson to rate "                  
                  width="100%"
                  
                ><v-card-text>This feedback help us to improve teaching and lessons quality.</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-for="booking in ratingPendingBookings" :key="booking.id">
                <v-card
                  class="mx-auto"
                  variant="outlined"
                  :prepend-icon="booking.ico"
                  append-icon="mdi-chevron-right"
                  :subtitle="booking.sub"
                  :title="booking.text"                  
                  width="100%"
                  :disabled="booking.isReadonly"
                  :to="{name: 'RecordFeedback', params: {}}"
                  >
                  <!-- to="/appview/feedbackview/recordfeedback/123" -->
                  <template v-slot:prepend>
                    <v-icon
                      :icon="booking.ico"
                      color="primary"
                      size="x-large"
                    ></v-icon>
                  </template>
                </v-card>
              </v-col>              
            </v-row>
          
  </template>
  
  <script setup>


  const ratingPendingBookings =  [
    {
      id: 1,
      text: "Rate lesson on 14-04-24",
      sub: "Is something not right, let us know",
      bookingID: 1001,
      ico: "mdi-star",
      isReadonly: false,
    },
    {
      id: 2,
      text: "Rate lesson on 10-04-24",
      sub: "Is something not right, let us know",
      bookingID: 1001,
      ico: "mdi-star",
      isReadonly: false,
    },
    {
      id: 3,
      text: "Rate lesson on 04-04-24",
      sub: "Is something not right, let us know",
      bookingID: 1001,
      ico: "mdi-star",
      isReadonly: false,
    },
    {
      id: 3,
      text: "Rate lesson on 24-03-24",
      sub: "Is something not right, let us know",
      bookingID: 1001,
      ico: "mdi-star",
      isReadonly: false,
    },
   
  ];
  </script>
  
  <style lang="scss" scoped></style>
  