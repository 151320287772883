import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import AppView from "../views/AppView.vue";
import DashView from "../views/DashView.vue";
import SchoolInfoView from "../views/school/SchoolInfoView.vue";
import InstructorInfoView from "../views/instructor/InstructorInfoView.vue";
import ComplaintsDash from "@/views/complaints/ComplaintsDash.vue";
import RecordComplaint from "@/views/complaints/RecordComplaint.vue";
import ActiveComplaints from "@/views/complaints/ActiveComplaints.vue";
import ResolvedComplaints from "@/views/complaints/ResolvedComplaints.vue";
//feedbacks
import FeedbackView from "@/views/feedbacks/FeedbackView.vue";
import RecordFeedback from "@/views/feedbacks/RecordFeedback.vue";
//buy lessons
import BuyLessons from "@/views/topupLessons/BuyLessons.vue";
//bookings
import BookingsDash from "@/views/bookings/BookingsDash.vue";
import PaymentHistory from "@/views/bookings/PaymentHistory.vue";
import CompletedBookings from "@/views/bookings/CompletedBookings.vue";
import UpcomingBookings from "@/views/bookings/UpcomingBookings.vue";
import BookLesson from "@/views/bookings/BookLesson.vue";
import ManageBooking from "@/views/bookings/ManageBooking.vue";

const fadeINUp = "animate__animated animate__faster animate__fadeInUp";
const fadeOutDown = "animate__animated animate__faster animate__fadeOutDown";
//const leftIN = "animate__animated animate__fast animate__slideInLeft";
const rightIN = "animate__animated animate__fast animate__slideInRight";
const leftOUT = "animate__animated animate__fast animate__slideOutLeft";
const rightOUT = "animate__animated animate__fast animate__slideOutRight";

const routes = [
  {
    path: "/",
    name: "home",
    component: LoginView,
    meta: {
      guest: true,
      enterClass: "animate__animated animate__faster animate__slideInTop",
      leaveClass: leftOUT,
      level: 0,
    },
  },
  {
    path: "/appview",
    name: "AppView",
    component: AppView,
    redirect: "dash",
    meta: {
      requiresAuth: true,
      //enterClass: leftIN,
      //leaveClass: leftOUT,
      level: 1,
      backto: null,
    },
    children: [
      //dash
      {
        path: "dash",
        name: "Dash",
        component: DashView,
        meta: {
          enterClass: fadeINUp,
          //leaveClass: leftOUT,
          level: 1,
          backto: null,
        },
      },
      //bookings
      {
        path: "bookingsdash",
        name: "BookingsDash",
        component: BookingsDash,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 2,
          backto: "/appview/dash",
        },
        /*children: [
         
        ],*/
      },
      //actual bookings children
      {
        path: "paymenthistory",
        name: "PaymentHistory",
        component: PaymentHistory,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 3,
          backto: "bookingsdash",
        },
      },
      {
        path: "completedbookings",
        name: "CompletedBookings",
        component: CompletedBookings,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 3,
          backto: "bookingsdash",
        },
      },
      {
        path: "upcomingbookings",
        name: "UpcomingBookings",
        component: UpcomingBookings,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 3,
          backto: "bookingsdash",
        },
      },
      {
        path: "booklesson",
        name: "BookLesson",
        component: BookLesson,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 3,
          backto: "bookingsdash",
        },
      },
      {
        path: "managebooking",
        name: "ManageBooking",
        component: ManageBooking,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 3,
          backto: "upcomingbookings",
        },
      },

      //buy lessons
      {
        path: "buylessons",
        name: "BuyLessons",
        component: BuyLessons,
        meta: {
          enterClass: rightIN,
          leaveClass: rightOUT,
          level: 2,
          backto: "/appview/dash",
        },
      },

      //feedbacks
      {
        path: "feedbackview",
        name: "FeedbackView",
        component: FeedbackView,
        meta: {
          enterClass: fadeINUp,
          leaveClass: fadeOutDown,
          level: 2,
          backto: "/appview/dash",
        },

        /*children: [
          //does not work when chidren added
        ],*/
      },
      {
        path: "recordfeedback",
        name: "RecordFeedback",
        component: RecordFeedback,
        meta: {
          enterClass: fadeINUp,
          leaveClass: fadeOutDown,
          level: 3,
          backto: "/appview/feedbackview",
        },
      },
      //instuctor info
      {
        path: "instructorinfoview",
        name: "InstructorInfoView",
        component: InstructorInfoView,
        meta: {
          enterClass: fadeINUp,
          leaveClass: fadeOutDown,
          level: 2,
          backto: "/appview/dash",
        },
      },

      //school info
      {
        path: "schoolinfoview",
        name: "SchoolInfoView",
        component: SchoolInfoView,
        meta: {
          enterClass: fadeINUp,
          leaveClass: fadeOutDown,
          level: 2,
          backto: "/appview/dash",
        },
      },
      //complaints
      {
        path: "complaintsdash",
        name: "ComplaintsDash",
        component: ComplaintsDash,
        meta: {
          enterClass: rightIN,
          leaveClass: leftOUT,
          level: 2,
          backto: "/appview/dash",
        },
        /*children: [
         
        ],*/
      },
      //actual childen
      {
        path: "recordcomplaint",
        name: "RecordComplaint",
        component: RecordComplaint,
        meta: {
          enterClass: rightIN,
          leaveClass: leftOUT,
          level: 3,
          backto: "complaintsdash",
        },
      },
      {
        path: "activecomplaints",
        name: "ActiveComplaints",
        component: ActiveComplaints,
        meta: {
          enterClass: rightIN,
          leaveClass: leftOUT,
          level: 3,
          backto: "complaintsdash",
        },
      },
      {
        path: "resolvedcomplaints",
        name: "ResolvedComplaints",
        component: ResolvedComplaints,
        meta: {
          enterClass: rightIN,
          leaveClass: leftOUT,
          level: 3,
          backto: "complaintsdash",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
