<template>

          <v-row dense>
            <v-col cols="12">
              <v-card
                class="mx-auto"
                subtitle="Please contact driving school for queires"
                title="Book lessons"
                width="100%"
                ><v-card-text>
                  <p>
                    <span>Lessons type:</span>
                    {{ leanerdata.learnerInfo.lessonsType }}
                  </p>
                  <p>
                    <span>Instructor:</span>
                    {{ leanerdata.instructorInfo.name }}
                  </p>
                  <p>
                    <span>Avaible Lessons:</span
                    ><v-chip variant="flat" class="ml-1" label density="comfortable" color="cyan">
                       <span class="cp">{{ leanerdata.learnerCreditInfo.available }} hours</span>
                    </v-chip>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
            ><BookLessonCard :credit="bookCredit" />

            </v-col>
          </v-row>
       
</template>

<script setup>
import BookLessonCard from "@/components/BookLessonCard.vue"
import { useLearnerStore } from "@/store/learner-store"
import { computed } from "vue"

const leanerdata = useLearnerStore()

const lessonCredits = [
{ id: 1000, paymentid: 1445 },
  { id: 1002, paymentid: 1455 },
  { id: 1003, paymentid: 1455 },
  { id: 1004, paymentid: 1465 },
  { id: 1005, paymentid: 1465 },
];
console.log(lessonCredits);
const bookCredit = computed(function(){
    if(lessonCredits.length > 0)
    {
        return lessonCredits[0]

    }
    return null
})
</script>

<style lang="scss" scoped>
p {
  line-height: 1.5rem;
  span {
    font-weight: 600;
  }
  span.cp{
    color: rgb(17, 132, 240);
  }
}
</style>
